import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import Section from '../../components/Section'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../../templates/landing-page.scss'
import './unsung-hero.scss'
import heroImage from '../../images/landing/unsung-hero.png'

import ModalVideo from '../../components/ModalVideo'
import imageVidMain from '../../images/section/unsung-hero-video-thumbnail.jpg'

class HubspotForm extends React.Component {

  constructor(props) {
	super(props)
  }

  componentDidMount() {
	const script = document.createElement('script');
	script.src = 'https://js.hsforms.net/forms/v2.js';
	document.body.appendChild(script);
	
	script.addEventListener('load', () => {
	  if(window.hbspt) {
		window.hbspt.forms.create({
		  portalId: '3837251',
		  formId: '30a9c06b-76e6-47e5-afc4-6e0dade235c9',
		  target: '#hubspotForm'
		})
	  }
	});
  }
  
  render() {
	return (
	  <div>
			<div id="hubspotForm"></div>
	  </div>
	);
  }
}

export default () => {
  return (
	<Layout
	  className={`LandingPage UnsungHero`}
	  title={`Nominate your Unsung Hero`}
	  locale={`en-NZ`}
		noIndex={true}
	>
	  <Hero>
			<Container>
				<Row>
					<Col lg={6} className="Hero__MainCol pl-0 pr-sm-6 pr-xs-0 d-flex flex-column justify-content-center align-items-start">
						<h1>Nominate your Unsung Hero</h1>
						<div className="intro">
							Celebrate your Unsung Hero and give them the chance to win tickets to the One NZ Warriors versus the Sea Eagles in August!
						</div>
					</Col>
					<Col lg={5} className="Hero__MainImg" >
						<img src={heroImage} />
					</Col>
				</Row>
			</Container>
	  </Hero>

	  <Section className="LandingPage__Main pt-5 pb-2" id="scrollToForm">
			<Container className="py-6">
				<Row>
				<Col lg={7} className="pb-5 referral-text">
					<h2>Who’s the MVP in your business?</h2>
					<p>It's time to give a shout-out to the legends behind small and medium-sized businesses. We're talking about the unsung heroes in your business who work tirelessly behind the scenes: the problem solvers, the administrators, the steady pair of hands – all crucial to the company's success.</p>
					<p>Nominate the unsung heroes in your business (or nominate yourself!) and show your appreciation for their hard work and vital role in keeping everything running smoothly.</p>
					<p>The winner scores flights, accommodation, spending money, and two tickets to watch the One NZ Warriors play the Sea Eagles in Sydney on 16 August.</p>

					<h3>The prize pack:</h3>
					<ul>
						<li>2x tickets to Warriors Vs. Sea Eagles in Sydney. Flights and accom included</li>
						<li>2x signed Warriors jerseys</li>
						<li>$500 spending money</li>
					</ul>

					<h3>How to enter:</h3>
					<p>Fill out the form on the right and add a comment, video, or doc about why you’re nominating your Unsung Hero.</p>
					<p>That’s all!</p>

					<h3>How and when we’ll pick the winners:</h3>
					<p>The competition is open between 12:00 am on 3 July 2024 and 11:59 pm on 30 July 2024. MyHR will sort through all nominations and select a winner the week of 29 July.</p>
					<p>Your Unsung Hero must be available from 15 - 17 August 2024 to win this prize.</p>
				</Col>

				<Col lg={5} className="pb-5">
					<HubspotForm />
				</Col>

				</Row>
			</Container>
		</Section>

		<Section className="Content ContentVideo mb-5 pt-0">
			<Container>
				<Row>
					<Col
						md={{ size: 8, offset: 2 }}
						className="text-center"
					>
						<ModalVideo 
							url= 'https://www.youtube.com/embed/siZXOJW3Ggk'	
							channel= 'youtube'
						>
							{({ toggle }) => (
								<div className="UntangleHrSection__video2" onClick={toggle}>
									<img src={imageVidMain} alt={'Video play'} />
									<div className="UntangleHrSection__video-play-icon">
										<FontAwesomeIcon
											icon={faPlay}
											className="icon play-icon"
										/>
									</div>
								</div>
							)}
						</ModalVideo>
					</Col>
				</Row>
			</Container>
		</Section>
	</Layout>
  );
}